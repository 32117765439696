<template>
  <body style="position: relative;">
    <div id="wrap">
      <section>
        <headerLayout></headerLayout>
      </section>
      <main>
        <section class="sub-visual">
          <div class="innWrap">
            <div class="txt">
              <h2>변경</h2>
              <!-- <p>국내 최대 용량의 기업전용망을 통한 고품질 인터넷 전용회선 서비스</p> -->
            </div>
            <div class="location">
              <ul>
                <li><a>ORDER</a></li>
                <li><a>서비스 신청</a></li>
                <li><span>변경</span></li>
              </ul>
            </div>
          </div>
        </section>
        <section class="sub-section svcmodfi">
          <div class="innWrap">
            <div class="lineBox">
              <div class="con01">
              <div class="multi">
                <div class="select" style="width: 85% !important">
                  <span class="placeholder">
                    <span class="fcGy">고객명 / 고객ID</span>
                  </span>
                  <div class="list">
                    <ul>
                      <li v-for="item in userInfo" :key="item.custId" :value="item.custId" @click="selectValue($event, 'CUST')">{{item.custNm}} / {{item.custId}}</li>
                    </ul>
                  </div>
                </div>
                <a @click="searchList" class="btn-md02 btn-primary">조회</a>
              <!-- <div class="searchBox-select">
                    <div class="select select-inner w288px" id="svcmodfi-title-01">
                      <span class="placeholder">
                        {{ this.selectedSearchTypeTitle }}
                      </span>
                      <div class="this.typeList_sel != '03' ? 'select w560px' : 'select w378px'">
                      <div class="select w378px">
                        <span class="placeholder" ref="custnmid"><span class="fcGy">고객명 / 고객ID</span></span>
                        <div class="list">
                          <ul>
                            <li v-for="item in userInfo" :key="item.custId" :value="item.custId" @click="selectValue($event, 'CUST')">{{item.custNm}} / {{item.custId}}</li>
                          </ul>
                        </div>
                      </div>
                      <div class="list">
                        <ul v-for="item in searchType" :key="item.typeCd" :data-name="item.title">
                          <li :value="item.typeCd" @click="selectValue($event, 'searchType')">
                            {{ item.title }}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <input
                      type="text"
                      id="svcmodfi-title-01"
                      placeholder="입력하세요"
                      class="input-line"
                      v-show="selectedSearchTypeCd === '01'"
                      v-model="partyIdNumber1"
                      maxlength="10"
                      readonly
                    />
                    <input
                      type="text"
                      id="svcmodfi-title-02"
                      placeholder="입력하세요"
                      class="input-line"
                      v-show="selectedSearchTypeCd === '02'"
                      v-model="partyIdNumber2"
                      maxlength="13"
                      readonly
                    />
                    <a @click="getUserSvcInfo" class="btn-md01 btn-primary">검색</a>
                  </div>
                    </div> -->
              </div>
              </div>
              <!-- //form-list -->

              <table class="tbl_type02 checkTbl mgt50">
                <colgroup>
                  <col width="4%" />
                  <col width="12%" />
                  <col width="17%" />
                  <col width="18%" />
                  <col width="10%" />
                  <col width="10%" />
                  <col width="*" />
                </colgroup>
                <thead>
                  <tr>
                    <th>선택</th>
                    <th>신청일자</th>
                    <th>신청서ID</th>
                    <th>상품명</th>
                    <th>서비스계약번호</th>
                    <th>회선번호</th>
                    <th>설치장소</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="statusList == ''">
                    <td colspan="7">
                      조회된 데이터가 없습니다.
                    </td>
                  </tr>
                  <tr v-show="statusList" v-for="item in statusList" :key="item.aplshtid" :class="data_sel.aplshtid == item.aplshtid ? 'selected' : ''">
                    <td>
                      <div class="checkbox tbl-ckRadio">
                        <input type="radio" :id="item.aplshtid" class="hide" name="ckRadio" :value="item" :data-name="item.svctypename" @click="selectData(item, 'SCN')"/>
                        <label :for="item.aplshtid"></label>
                      </div>
                    </td>
                    <td>{{ item.rgstdt }}</td>
                    <td>{{ item.aplshtid }}</td>
                    <td>{{ item.svctypenm }}</td>
                    <td>{{ item.said }}</td>
                    <td>{{ item.connectlineno }}</td>
                    <td>{{ item.addr.replaceAll('^', ' ') }}</td>
                  </tr>
                </tbody>
              </table>

              <h4 v-show="data_sel != ''">서비스 정보</h4>
              
              <table class="tbl_type01" v-show="data_sel != ''">
                <tbody>
                  <colgroup>
                    <col style="width: 200px;" />
                    <col style="" />
                    <col style="width: 120px;" />
                  </colgroup>
                  <tr>
                    <th>설치장소</th>
                    <td>
                      <span class="">
                        {{ data_sel.addr }}
                      </span>
                    </td>
                    <td class="agc">
                      <a @click="openPopup('locationInfo')" class="btn-xs btn-pink pop-open" 
                      v-show="svcMasterInfo && !(data_sel.svctypecd == '11' || data_sel.svctypecd == '20' || data_sel.svctypecd == '30')">변경</a>
                    </td>
                  </tr>
                  <tr>
                    <th>속도</th>
                    <td>
                      <!-- 500M -->
                      {{ data_sel.linespecnm }} / {{ data_sel.uploadspdnm}}
                    </td>
                    <td class="agc">
                      <a v-show="svcMasterInfo && (data_sel.svctypecd == '11' || data_sel.svctypecd == '20' || data_sel.svctypecd == '30')"
                       @click="openPopup('basicSvcInfo')" class="btn-xs btn-pink pop-open">변경</a>
                    </td>
                  </tr>
                  <tr>
                    <th>추가 IP</th>
                    <td>
                      <!-- 0개 -->
                      {{data_sel.addipcnt == null ? "없음" : (data_sel.addipcnt == "" ? "없음" : data_sel.addipcnt + "개")}}
                    </td>
                    <td class="agc">
                      <a v-show="svcMasterInfo && (data_sel.svctypecd == '11' || data_sel.svctypecd == '20' || data_sel.svctypecd == '30')"
                      @click="openPopup('basicSvcInfo')" class="btn-xs btn-pink pop-open">변경</a>
                    </td>
                  </tr>
                  <tr>
                    <th>부가서비스</th>
                    <td>
                      {{ data_sel.subsanm == null ? '없음' : 'Cloud Direct (' + data_sel.subsanm + ')'}}
                    </td>
                    <td class="agc">
                      <a v-show="data_sel.svctypecd == '11' || data_sel.svctypecd == '20' || data_sel.svctypecd == '30'"
                      @click="openPopup('addSvcInfo')" class="btn-xs btn-pink pop-open">변경</a>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div class="modfitotal grayBox mgt50" v-show="data_sel">
                <div class="amount">
                  <p class="fcPurple2 fw400">예상 변동 요금</p>
                  <p>
                    {{termCntrctChg_gap + uploadCost_gap + addsvcCntrctChg_gap + useAmt_sub_gap| comma}}<span class="fc111">원</span><span>(부가세 별도)</span>
                  </p>
                  <p class="equals"></p>
                  <div class="detail">
                    <dl> 
                      <dt>기본서비스 (월납)</dt>
                      <dd>{{termCntrctChg_gap | comma}}<span>원</span></dd>
                    </dl>
                    <dl>
                      <dt>업로드 속도 증속 (월납)</dt>
                      <dd>{{uploadCost_gap | comma}}<span>원</span></dd>
                    </dl>
                    <dl>
                      <dt>IP 변경 (월납)</dt>
                      <dd>{{addsvcCntrctChg_gap | comma}}<span>원</span></dd>
                    </dl>
                    <dl>
                      <dt>부가서비스 (월납)</dt>
                      <dd>{{useAmt_sub_gap | comma}}<span>원</span></dd>
                    </dl>
                    <dl>
                      <dt>이전 설치비 (일회성)</dt>
                      <dd>{{cntrctInstChg_after | comma}}<span>원</span></dd>
                    </dl>
                  </div>
                </div>
              </div>

              <div class="btnBottomArea" v-show="data_sel">
                <a
                  class="btn-lg fix btn-primary"
                  @click="goOrder"
                  >신청</a
                >
              </div>
            </div>
          </div>
        </section>
      </main>
      <div id="pop-address02" class="pop-wrap pop-address02" v-show="popAddrInfo" style="display:block; !important">
        <div class="pop-inner pop-md">
            <div class="pop-tit">
                주소검색
                <a @click="popAddrInfo = false" class="close pop-close-btn"></a>
            </div>
            <div class="pop-con">
                <div>
                    <div class="pop-address">
                        <label for="pop-address02-01" class="form-labelh52 w80px agl">주소검색</label>
                        <div class="input-search h52">
													<input type="search" id="pop-address02-01" placeholder="동 이름을 입력하세요." class="w500px h52" ref="dong2" v-model="dong" @keyup.enter="getCustAddressList">
                            <a @click="getCustAddressList"></a>
                        </div>
                    </div>
                    <p class="asterisk_bk">※ 검색 결과 선택하시면 자동으로 입력됩니다.</p>
                    <div class="scrollInner-pop">
                        <ul>
                            <li v-for="item in addrList" :key="item.addrseq" @click="addrInfoTemp(item)"><a>{{item.addr}}</a></li>
                        </ul>
                    </div>
                    <div class="pop-btnBottomArea">
											<a @click="closePopup('addrInfo')" class="btn-md01 btn-primary close">확인</a>
                    </div>
                </div>

            </div>
        </div>
      </div> <!-- // pop-address02-->

      <div id="pop-address03" class="pop-wrap pop-address03" v-show="popBuildingInfo" style="display:block; !important">
        <div class="pop-inner pop-md">
            <div class="pop-tit">
                건물검색
							<a @click="popBuildingInfo=false" class="close pop-close-btn"></a>
            </div>
            <div class="pop-con">
                <div>
                    <div class="pop-address">
                        <label for="pop-address03-01" class="form-labelh52 w80px agl">주소</label>
                        <div class="input-search h52">
													<input type="text" id="pop-address03-01" v-model="this.addrInfo_temp.addr" class="w500px input-line" readonly>
                            <a href="#"></a>
                        </div>
                    </div>
                    <div class="pop-address mgt20">
                        <label for="pop-address03-02" class="form-labelh52 w80px agl">건물명</label>
                        <div class="input-search h52">
													<input type="search" id="pop-address03-02" v-model="bldgname" placeholder="건물명을 입력하세요" class="w500px h52" @keyup.enter="getCustAddressBldgList">
                            <a @click="getCustAddressBldgList"></a>
                        </div>
                    </div>
                    <div class="pop-address mgt20">
                        <label for="pop-address03-03" class="form-labelh52 w80px agl">상세주소</label>
                        <div class="input-search h52">
													<input type="search" id="pop-address03-03" v-model="detailAddr" placeholder="상세주소를 입력하세요" class="w500px h52" @keyup.enter="closePopup('buildingInfo')">
                            <!-- <a href="#"></a> -->
                        </div>
                    </div>
                    <p class="asterisk_bk">※ 검색 결과 선택하시면 자동으로 입력됩니다.</p>
                    <div class="scrollInner-pop">
                        <ul>
													<li v-for="item in buildingList" :key="item.bldgscode" @click="buildingInfoTemp(item)">{{item.addr1 + item.addr2}}</li>
                        </ul>
                    </div>
                    <div class="pop-btnBottomArea">
											<a class="btn-md01 btn-primary close" @click="closePopup('buildingInfo')">확인</a>
                    </div>
                </div>
            </div>
          </div>
        </div> <!-- // pop-address03-->

        <div id="pop-address" class="pop-wrap" v-show="popLocationInfo" style="display:block; !important">
            <div class="pop-inner pop-lg scrollInner-pop">
                <div class="pop-tit">설치장소
                    <a @click="popLocationInfo=false" class="close pop-close-btn"></a>
                </div>
                <div class="pop-con">
                    <div class="pop-address">
                        <label for="pop-address02" class="form-labelh60 w80px agl">주소검색</label>
                        <div class="input-search">
                            <input type="search" id="pop-address02" placeholder="동 이름을 입력하세요." class="w490px" v-model="dong" @keyup.enter="openPopup('addrInfo')" ref="dong">
                            <a @click="openPopup('addrInfo')" class="pop-open"></a>
                        </div>
                        <label for="pop-address03" class="form-labelh60 w100px">건물검색</label>
                        <div class="input-search">
                            <input type="search" id="pop-address03" placeholder="동 이름을 입력하세요." class="w490px" v-model="bldgname" @keyup.enter="openPopup('buildingInfo')" ref="building">
                            <a @click="openPopup('buildingInfo')" class="pop-open"></a>
                        </div>
                    </div>
                </div>
                <div class="pop-tit mgt60">건물조회 결과</div>
                <div class="pop-con">
                    <div class="tbl_scroll">
                        <table class="tbl_type04">
                            <colgroup>
                                <!-- <col style="width: 80px"> -->
                                <col style="width: 240px">
                                <col style="width: *" />
                                <col style="width: 167px">
                            </colgroup>
                            <thead>
                                <tr>
                                    <!-- <th>선택</th> -->
                                    <th>건물명</th>
                                    <th>주소(지번)</th>
                                    <th>건물유형</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-show="buildingInfo_temp">
                                    <!-- <td class="agc">
                                        <div class="checkbox tbl-ckRadio">
                                            <input type="checkbox" id="pop-addrchk" class="hide" name="ckSingle">
                                            <label for="pop-addrchk"></label>
                                        </div>
                                    </td> -->
                                    <td>{{buildingInfo_temp.bldgname}}</td>
                                    <td>{{`${buildingInfo_temp.addr1}${buildingInfo_temp.addr2} ${detailAddr}`}}</td>
                                    <td>{{buildingInfo_temp.bldgtypenm}}</td>
                                </tr>
                                <tr v-show="!buildingInfo_temp">
                                  <td colspan="3" style="text-align: center;">
                                    조회 결과가 없습니다.
                                  </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="pop-btnBottomArea">
                    <a @click="closePopup('locationInfo')" class="btn-md01 btn-primary close">확인</a>
                </div>
            </div>
        </div> <!-- // pop-address -->

        <div id="pop-basicSvcInfo" class="pop-wrap pop-basicSvcInfo" style="display: block;" v-show="popBasicSvcInfo">
        <div class="pop-inner pop-lg scrollInner-pop">
        <div class="pop-tit">기본 서비스 정보
            <a @click="popBasicSvcInfo=false" class="close pop-close-btn"></a>
        </div>
        <div class="pop-con">
            <div class="form-list">
                <div class="form-Item item01">
                    <label for="">가입유형<span class="required">*</span></label>
                    <div class="fcon multi">
                        <div class="radio h52 two multi">
                            <input type="radio" name="svcgender" id="svcgender_1" value="" disabled />
                            <label for="svcgender_1" class="mgr20">신규</label>
                            <input type="radio" name="svcgender" id="svcgender_2" value="" checked/>
                            <label for="svcgender_2">전환</label>
                        </div>
                        <div>
                            <!-- <input type="text" id="" placeholder="기존 서비스 유형" disabled> -->
                            <label for="" class="lable_2 form-labelh52" style="margin-left: 30px;">기존 회선번호</label>
                            <input type="text" placeholder="기존 회선번호" disabled v-model="svcMasterInfo.llnum">
                            <label for="" class="lable_2 form-labelh52" style="margin-left: 30px;">기존 요금</label>
                            <input type="text" placeholder="기존 요금" disabled v-model="data_sel.useamt">
                        </div>
                    </div>
                </div>
                <div class="form-Item item02">
                    <label for="basicSvccon-02">서비스명<span class="required">*</span></label>
                    <div class="fcon multi">
                        <div class="select w500px disabled" id="basicSvccon-02-01">
                            <span class="placeholder"><span class="fcGy">{{data_sel.svctypenm}}</span></span>
                            <!-- <div class="list">
                                <ul>
                                    <li value="FL" @click="productInfoFilter">Flexline</li>
                                    <li value="GO" @click="productInfoFilter">GiGa Office</li>
                                </ul>
                            </div> -->
                        </div>
                        <!-- <div class="select w500px disabled" id="basicSvccon-02-02">
                            <span class="placeholder"><span class="fcGy">{{data_sel.svctypecodedesc}}</span></span>
                            <div class="list">
                                <ul>
                                  <li v-for="item in productInfo_temp" :key="item.svc_type_cd" :value="item.svc_type_cd" :data-name="item.svc_type_nm" @click="selectValue($event, 'DS')">{{item.svc_type_nm}}</li>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="form-Item item03">
                    <label for="basicSvccon-03">속도<span class="required">*</span></label>
                    <div class="fcon1 multi">
                        <div class="select" id="basicSvccon-03-01">
                            <span class="placeholder" ref="downSpdSpan"><span class="fcGy"></span>다운로드 속도</span>
                            <div class="list">
                                <ul>
                                  <li v-for="item in lineSpeedInfo" :key="item.code_cd" :value="item.code_cd" :data-name="item.code_nm" @click="selectValue($event, 'LS')">{{item.code_nm}}</li>
                                </ul>
                            </div>
                        </div>
                        <div :class="data_sel.svctypecd != '20' ? 'select disabled' : 'select'" id="basicSvccon-03-01" style="margin-left:32px;">
                            <span class="placeholder" ref="uploadSpdSpan"><span class="fcGy">업로드 속도</span></span>
                            <div class="list">
                                <ul>
                                  <li v-for="item in lineUploadSpeedInfo" :key="item.code_cd" :value="item.code_cd" :data-index="item.displayseq" :data-name="item.code_nm" @click="selectValue($event, 'LSU')" ref="uploadSpdLi">{{item.code_nm}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
										<label id="aa" class="lable_2 form-labelh52" style="margin-left: 20px; margin-right: 20px;">기본 제공 IP</label>
										<input id="aa" type="text" style="width:107px; text-align:center; important!" readonly :value="`${data_sel.ip_addrcnt}   `">
                    <!-- <label for="basicSvccon-04" class="lable_2 form-labelh52">고정 IP</label> -->
                    <!-- <div class="fcon2 multi">
                        <div class="input_num">
                            <span class="btn-minus"></span>
                            <input type="text" id="basicSvccon-04-01" placeholder="0">
                            <span class="btn-plus"></span>
                        </div>
                    </div> -->
                    <label for="" class="lable_2 form-labelh52">추가 IP</label>
                    <div class="fcon2 multi">
                        <div class="input_num">
                            <span class="btn-minus" @click="getaddIpCnt_temp('-')"></span>
                            <input type="text" id="" placeholder="" v-model="addIpCnt_temp">
                            <span class="btn-plus" @click="getaddIpCnt_temp('+')"></span>
                        </div>
                    </div>
                </div>
                <div class="form-Item item05">
                    <label for="basicSvccon-05">계약기간</label>
                    <div class="fcon multi">
                      <div class="radio h52 five multi">
                        <input type="radio" name="term" id="term_1" v-model="data_sel.termcntrcttypecd" value="EQ0Y" disabled/>
                        <label for="term_1">무약정</label>
                        <input type="radio" name="term" id="term_2" v-model="data_sel.termcntrcttypecd" value="EQ2Y" disabled/>
                        <label for="term_2">2년 (10%)</label>
                        <input type="radio" name="term" id="term_3" v-model="data_sel.termcntrcttypecd" value="EQ3Y" disabled/>
                        <label for="term_3">3년 (15%)</label>
                        <input type="radio" name="term" id="term_4" v-model="data_sel.termcntrcttypecd" value="EQ4Y" disabled/>
                        <label for="term_4">4년 (20%)</label>
                        <input type="radio" name="term" id="term_5" v-model="data_sel.termcntrcttypecd" value="EQ5Y" disabled/>
                        <label for="term_5">5년 (25%)</label>
                      </div>
                    </div>
                </div>
                <div class="form-Item item06 label_2ea">
                    <label for="basicSvccon-06">협력사</label>
                    <div class="fcon1 multi">
                        <div class="select disabled" id="basicSvccon-06-01">
                            <span class="placeholder"><span class="fcGy">{{data_sel.maintagentnm == null ? '-' : data_sel.maintagentnm}}</span></span>
                            <!-- <div class="list">
                                <ul>
                                    <li>GiGA Switch</li>
                                    <li>10G GiGA Switch</li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                    <label for="basicSvccon-06" class="lable_2 agc pd0">공급장비</label>
                     <div class="fcon2 multi">
                        <div class="select disabled" id="basicSvccon-07" style="width:463px;!important">
                            <span class="placeholder"><span class="fcGy">{{data_sel.equipgrpnm == null ? '-' : data_sel.equipgrpnm}}</span></span>
                            <!-- <div class="list">
                                <ul>
                                    <li>위드네트웍스</li>
                                    <li>넷케이티아이</li>
                                    <li>기타</li>
                                </ul>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="form-Item item08">
                    <label for="basicSvccon-08">개통희망일</label>
                    <div class="fcon multi">
                        <input type="text" autocomplete="off" placeholder="YYYY/MM/DD" id="datebasicSvc" disabled v-model="data_sel.completedresvdatehh"/>
                        <a href="javascript:void(0)" class="btn-md01 btn-primary btn-disabled" id="open" type="button">달력</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="pop-btnBottomArea">
            <a @click="closePopup('basicSvcInfo')" class="btn-md01 btn-primary close">확인</a>
        </div>
    </div>
    </div> <!-- // pop-basicSvcInfo -->

        <div id="pop-addSvcInfo" class="pop-wrap pop-addSvcInfo" v-show="popAddSvcInfo" style="display:block; !important">
            <div class="pop-inner pop-lg scrollInner-pop">
                <div class="pop-tit">부가 서비스 정보
                    <a @click="popAddSvcInfo=false" class="close pop-close-btn"></a>
                </div>
                <div class="pop-con">
                    <div class="form-list">
                        <div class="form-Item item01 label_2ea">
                            <label for="addSvccon-01">Cloud Direct</label>
                            <div class="fcon">
                                <div class="multi">
                                    <div class="fcon1">
                                      <div class="radio h52 two multi">
                                          <input type="radio" name="cloud" id="cloud_1" value="false" ref="cloudDirectF" checked @click="cloudDirectChange($event, false)"/>
                                          <label for="cloud_1">선택안함</label>
                                          <input type="radio" name="cloud" id="cloud_2" value="true" ref="cloudDirectT" @click="cloudDirectChange($event, true)"/>
                                          <label for="cloud_2">선택</label>
                                      </div>
                                    </div>
                                    <!-- <label for="addSvccon-01-01" class="lable_2 w150px agc pd0">기본서비스</label> -->
                                    <!-- <input type="text" id="addSvccon-01-02" value="Flexline"> -->
                                </div>

                                <div class="multi mgt20" v-show="cloudDirectFlag">
                                    <span class="stit lable_2">제공</span>
                                    <div class="select" id="addSvccon-01-03">
                                        <span class="placeholder" ref="cldSvc"><span class="fcGy">선택하세요</span></span>
                                        <div class="list">
                                          <ul v-for="item in cloudDirectInfo" :key="item.displayseq">
                                              <li :value="item.code_cd" @click="selectValue($event, 'CD')" :data-name="item.code_nm">{{item.code_nm}}</li>
                                          </ul>
                                        </div>
                                    </div>
                                    <span class="stit2 lable_2">속도</span>
                                    <div class="select" id="addSvccon-01-04">
                                        <span class="placeholder" ref="cldSvcSpd"><span class="fcGy">최대 속도</span></span>
                                        <div class="list">
                                          <ul v-for="item in cloudDirectSpeed" :key="item.code_cd">
                                            <li :value="item.code_cd" @click="selectValue($event, 'CS')" :data-name="item.code_nm">{{item.code_nm}}</li>
                                          </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="asterisk_bk pdl140">
                            ※ Cloud 연결 제공 가능 서비스 : Flexline Premium / Basic<br />
                            ※ 각 서비스별 최대 속도 범위가 상이할 수 있습니다
                        </p>
                    </div>
                </div>

                <div class="pop-btnBottomArea">
                    <a @click="closePopup('addSvcInfo')" class="btn-md01 btn-primary close">확인</a>
                </div>
            </div>
        </div> <!-- // pop-basicSvcInfo -->
      <section>
        <footerLayout></footerLayout>
      </section>
    </div>
  </body>
</template>
<script>
import HeaderLayout from "./common/Header.vue";
import FooterLayout from "./common/Footer.vue";
import { gigaofficeService } from "Api";

export default {
  components: {
    HeaderLayout,
    FooterLayout
  },
  data () {
    return {
      popLocationInfo: false,
      popBasicSvcInfo: false,
      popAddSvcInfo: false,
			popAddrInfo: false,
      popBuildingInfo: false,
      cloudDirectFlag: false,
      
      locationFlag : false,
      buildingFlag : false,
      addrFlag : false,
      basicSvcFlag : false,
      addSvcFlag: false,
      // searchType: [
      //   { title: "사업자등록번호", typeCd: "01" },
      //   { title: "법인번호", typeCd: "02" }
      // ],
      data_sel: '',
      data_view: '',
      selectedSearchTypeCd: "01",
      selectedSearchTypeTitle: "사업자등록번호",
      selectedValue: '',
      svcMasterInfo: '',
      addservices: '',
      partyIdNumber1: '',
      partyIdNumber2: '',
      userSvcInfo: '',
      custType: '',
      custNum: '',
      subscriptionList: '',
      userInfo: '',
      userInfo_result: '',
      partnerInfo: '',
      cloudDirectInfo: '',
      cloudDirectSpeed: '',
      cloudDirectCostInfo: '',
      lineSpeedInfo: '',
      lineUploadSpeedInfo: '',
      productCostInfo: '',
      productInfo: '',
      subProductInfo: '',
      rentEquipmentInfo: '',
      statusList: '',
      //임시 저장 값
      userInfo_temp: '',
      accountInfo_temp: '',
      addrInfo_temp: '',
      buildingInfo_temp: '',
      centerInfo_temp: '',
      productInfo_temp: '',
      uploadSpeedIndex: '',
      useAmt_temp: 0,
      lineSpeedInfo_temp: '',
      lineSpeedInfoNm_temp: '',
      lineSpeedInfo_cal: '',
      //선택 값
      addrInfo_sel: '',
      buildingInfo_sel: '',
      fullAddr: '',
      svcTypeNm: '',
      subSaNm: '',
      svcNm: '',
      //선택 값 selectBox
      rentEquipmentInfo_sel: '',
      partnerInfo_sel: '',
      cloudDirectInfo_sel: '',
      lineSpeedInfo_sel: '',
      lineSpeedInfoNm_sel: '',
      uploadSpeedInfo_sel: '',
      uploadSpeedInfo_temp: '',
      uploadSpeedNm_temp: '',
      uploadSpeedNm_sel: '',
      sellerTypeInfo_sel: '',
      cloudDirectSpeed_sel: '',
      cloudDirectSpeedNm_sel: '',
      custNoType: '',
      payMethCd: '',
      acrndDspTypeCd: '',
      partyDetailTypeCd: '',
      dong: '',
      bldgname: '',
      addrList: '',
      buildingList: '',
      buildingAddr1: '',
      buildingAddr2: '',
      detailAddr: '',
      termCntrctTypeCd: 'EQ0Y', // 계약기간코드
      basicIpCnt: 1,
      addIpCnt_temp: 0,
      addIpCnt_before: 0,
      selNm: '',
      selId: '',
      svcTypeCd: '',
      useAmt: 0,
      nrcExemptRsnCd: '', //설치비면제여부
      //기존비용
      cntrctInstChg: 100000, //설치비
      equipUseChg: 0, // 장비임대료
      termCntrctChg: 0, //기간비용
      svcTotalChg: 0, //상품비용(기간비용+설치비)
      //상품비용
      cntrctInstChg_temp: 100000, //설치비
      equipUseChg_temp: 0, // 장비임대료
      termCntrctChg_temp: 0, //기간비용
      svcTotalChg_temp: 0, //상품비용(기간비용+설치비)

      cntrctInstChg_after: 100000, //설치비
      equipUseChg_after: 0, // 장비임대료
      termCntrctChg_after: 0, //기간비용
      svcTotalChg_after: 0, //상품비용(기간비용+설치비)

      cntrctInstChg_before: 100000, //설치비
      equipUseChg_before: 0, // 장비임대료
      termCntrctChg_before: 0, //기간비용
      svcTotalChg_before: 0, //상품비용(기간비용+설치비)
      
      //기존업로드속도비용
      uploadCost_before: 0,
      //업로드속도 추가에 따른 비용
      uploadCost_after: 0,

      //ip추가에 따른 비용
      addsvcCntrctChg: 0, //추가 IP비용
      addsvcCntrctChg_before: 0, //기존 추가 IP비용
      addsvcCntrctChg_temp: 0, //추가 IP비용
      
      //cloud direct
      
      useAmt_sub_before: 0, //기존금액
      cntrctInstChg_sub: 100000, //설치비
      equipUseChg_sub: 0, // 장비임대료
      svcTotalChg_sub: 0, // 총비용(설치비+장비임대료)
      
      useAmt_sub_temp: 0,
      cntrctInstChg_sub_temp: 100000, //설치비
      equipUseChg_sub_temp: 0, // 장비임대료
      svcTotalChg_sub_temp: 0, // 총비용(설치비+장비임대료)

      useAmt_sub_after: 0,
      cntrctInstChg_sub_after: 100000, //설치비
      equipUseChg_sub_after: 0, // 장비임대료
      svcTotalChg_sub_after: 0, // 총비용(설치비+장비임대료)

      useAmt_gap: 0, //예상변동요금 총
      termCntrctChg_gap: 0,
      addsvcCntrctChg_gap: 0, //예상변동요금 IP
      uploadCost_gap: 0, //예상변동요금 업로드속도변경
      useAmt_sub_gap: 0, //예상변동요금 부가서비스

    };
  },
  mounted: async function() {
    // 동시에 호출하면 lamplog pk 에러 때문에 await
    await this.getOwnerContactInfoByTokenId();
		// await this.getRentEquipmentList()
		// await this.getPartnerList()
		await this.getCloudDirectList()
		// await this.getSellerTypeList()
		await this.goProductCostInfo()
		await this.goProductInfo()
		await this.getGoSubProductInfo()
		await this.getCloudDirectSpeedList()
  },
  methods: {
    // 서비스명 변경 시 서비스명, 속도, IP 초기 값 세팅
		async setData(type) {
			this.termCntrctChg = 0 //상품금액
      this.addIpCnt_temp = parseInt(this.data_sel.addipcnt)
			// 다운로드 속도 목록 조회
			// await this.getLineSpeedList(this.svcTypeCd)
      this.useAmt_sub_before = this.data_sel.bugauseamt
			this.lineUploadSpeedInfo = ''
      this.$refs.downSpdSpan.innerHTML = `<span class="">${this.data_sel.linespecnm}</span>`
      this.$refs.uploadSpdSpan.innerHTML = `<span class="">${this.data_sel.uploadspdnm}</span>`
      this.lineSpeedInfo_temp = this.data_sel.linespeccd
      this.uploadSpeedInfo_temp = this.data_sel.uploadspd
      this.lineSpeedInfoNm_temp = this.data_sel.linespecnm
      this.uploadSpeedNm_temp = this.data_sel.uploadspdnm


      // this.data_send.linespeccd = ''123123
      // this.data_send.uploadspd = ''
			switch(this.data_sel.svctypecd) {
				case '11' ://basic
				// this.basicIpCnt = 2
				break;
				case '20' ://premium
				// this.basicIpCnt = 16
				//Premium은 속도 비대칭
        this.lineUploadSpeedInfo = this.lineSpeedInfo
				if(type=='init')
        {
          //기존 추가 IP 개수
          this.addIpCnt_before = this.data_sel.addipcnt
          // this.lineSpeedInfo_cal = 
          
          //기본 200M
          this.uploadSpeedInfo_sel = '80'
          this.$refs.uploadSpdSpan.innerHTML = `<span class="">200M</span>`
        }
        break;
				case '30' ://premium+
					// this.basicIpCnt = 128
				break;
				case '31' :
				case '32' ://standard or compact
					// this.basicIpCnt = 8
				break;
				case '34' ://simple
					// this.basicIpCnt = 4
				break;
			}
			if(this.data_sel.svctypecd == '11' || this.data_sel.svctypecd == '20') {
				this.$refs.cloudDirectT.disabled = false
				this.$refs.cloudDirectF.disabled = false
			} else {
				this.cloudDirectFlag = false
				this.cloudDirectInfo_sel = ''
				this.cloudDirectSpeed_sel = ''
				this.$refs.cloudDirectT.disabled = true
				this.$refs.cloudDirectF.disabled = true
			}
			this.getAddIpCost()
			this.getCost()
		},
    init() {
      this.termCntrctChg_gap = 0
      this.termCntrctChg_temp = 0
      this.termCntrctChg_before = 0

      this.uploadCost_gap = 0
      this.uploadCost_after = 0
      this.uploadCost_before = 0

      this.addsvcCntrctChg_gap = 0
      this.addsvcCntrctChg_temp = 0
      this.addsvcCntrctChg_before = 0

      this.useAmt_sub_gap = 0
      this.svcTotalChg_sub_temp = 0
      this.useAmt_sub_before = 0

      
      this.lineSpeedInfo_temp = ''
      this.lineSpeedInfoNm_temp = ''
      this.uploadSpeedInfo_temp = ''
      this.uploadSpeedNm_temp = ''
    },
    getUserData() {
      this.userInfo_result = this.userInfo.filter(item => item.custId == this.data_sel.custind)
    },
    async selectData(obj, type) {
      this.data_sel = {}
      this.init()
      switch (type) {
        case 'SCN' :
          // eslint-disable-next-line no-undef
          this.data_sel = structuredClone(obj)
          // eslint-disable-next-line no-undef
          this.data_view = structuredClone(obj)
          this.data_sel.addr = this.data_sel.addr.replaceAll('^', ' ')
          this.data_sel.addipcnt = this.data_sel.addipcnt == null ? 0 : this.data_sel.addipcnt
          // console.log(this.data_sel)
          // this.getUserSvcInfo()
          // this.selectedValue = v;
          this.svcMasterInfo = ''
          // this.data_sel = ''
          this.addservices = ''
          if(this.data_sel.svctypecd != '11') {
            await this.getSvcMasterInfo(this.data_sel.said)
          } else {
            await this.getSvcMasterInfo2(this.data_sel.said)
          }
          await this.getCost('init')

          this.getUserData()
          break;
      }
      this.setData('init')
    },
    async selectValue(e, type) {
			let v = e.currentTarget.getAttribute('value')
			let vnm = e.currentTarget.getAttribute('data-name')
			let i = e.currentTarget.getAttribute('data-index')
			switch (type) {
        case 'searchType' :
          this.selectedSearchTypeCd = v
          this.selectedSearchTypeTitle = vnm
        break;
        case 'CUST' :
          this.custId_sel = v
          break;
        case 'SCN' :
 
        break;
        case 'CD' :
            this.cloudDirectInfo_sel = v
            this.getCloudDirectCostInfo()
            this.subSaNm = vnm
            break;
        case 'LS' :
            this.lineSpeedInfo_temp = v
            this.lineSpeedInfoNm_temp = vnm
            this.useAmt_temp = 0
            if(!this.svcTypeCd == '20') {
              this.$refs.uploadSpdSpan.innerHTML = `<span class="fcGy">업로드 속도</span>`
              this.uploadSpeedInfo_sel = ''
            }
            this.getCost()
            break;
        case 'LSU' :
          this.uploadSpeedInfo_temp = v
          this.uploadSpeedNm_temp = vnm
          this.uploadSpeedIndex = i

          // setTimeout(() => {
          // Premium 업로드 200M 부터 100M 단위 증속 시, 10만원씩 추가 (단순계산)
          // console.log('--------------------') //1G 0 200M 8 300M 7
          if(this.data_sel.svctypecd == '20') {
            var temp = this.useAmt_temp
            console.log(this.lineSpeedInfo_cal.displayseq)
            if(this.uploadSpeedIndex > 2) {
              // console.log('temp1')
              this.uploadCost_after = (this.uploadSpeedIndex-2)*100000
              this.uploadCost_before = ((parseInt(this.lineSpeedInfo_cal.displayseq-2)))*100000
              console.log(this.uploadCost_after)
              console.log(this.uploadCost_before)
              temp += parseInt(this.uploadCost_after)
            } else if(this.uploadSpeedIndex == 2) {
              // console.log('temp2')
              // console.log(temp)
              this.uploadCost_after = 0
              // this.useAmt = temp
            } else {
              return false
            }
          }
          // this.useAmt += temp
          // this.useAmt_temp = this.useAmt
          // }, 50);
          this.getCost()
          break;
        case 'CS' :
          this.cloudDirectSpeed_sel = v
          this.cloudDirectSpeedNm_sel = vnm
          this.getSubCost()
			}    
    },
    async searchList() {
			let param = {
				tokenId : sessionStorage.getItem('accessToken')
			, userId: sessionStorage.getItem('userId')
			, custInd: this.custId_sel
			// , scn: this.scnList_sel
			, type: '01' //codemst group_cd : 'OD'
			}
			//신청내역조회 api호출
			await this.$store.dispatch('gigaoffice/getOrderStatusList', param).then((res) => {
        this.statusList = res.data.response.list.filter(item => item.trtmstatcd == '3')
			})
			.catch(error => {
				console.log(error)
			})
    },
    // 공통코드 조회
		async getComCodeList(group_cd, code_cd) {
			let payload = {
				group_cd : group_cd,
				code_cd : code_cd 
			}
			let response = {}
			await this.$store.dispatch('gigaoffice/getComCodeList', payload).then(res => {
				response = res
			})
			.catch(err => {
				console.log(err)
			})
			return response
		},
    // 대여장비 조회
		// async getRentEquipmentList() {
		// 	const res = await this.getComCodeList('RE')
		// 	this.rentEquipmentInfo = res.data.response.results
		// },
		// 협력사 조회
		async getPartnerList() {
			const res = await this.getComCodeList('PN')
			this.partnerInfo = res.data.response.results
		},
		// 회선속도코드 조회
		async getLineSpeedList(svcTypeCd) {
			const res = await this.getComCodeList('LS')
			var result = res.data.response.results
      this.lineSpeedInfo_cal = result
      this.lineSpeedInfo_cal = this.lineSpeedInfo_cal.find((item) => item.code_cd == this.data_sel.uploadspd)

			// GIGAoffice Standard : 1G
			if(svcTypeCd == '31') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '39')
			// GIGAoffice Compact : 500M
			} else if (svcTypeCd == '32') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '95')
			// GIGAoffice Simple : 200M
			} else if (svcTypeCd == '34') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '80')
			// Flexline Basic: 100M, 200M, 500M, 1G
			} else if (svcTypeCd == '11') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd == '3A' || item.code_cd == '80' || item.code_cd == '95' || item.code_cd == '39')
			// Flexline Premium: 200M ~ 1G
			} else if (svcTypeCd == '20') {
				this.lineSpeedInfo = result.filter((item) => item.code_cd != '3A')
			// Flexline Premium+: 100M ~ 1G
			} else if(svcTypeCd == '30') {
				this.lineSpeedInfo = result
			}
			// this.lineUploadSpeedInfo = this.lineSpeedInfo
		},
    // 상품정보
    async goProductCostInfo () {
      try {
        const payload = {
          token_id: sessionStorage.getItem('accessToken')
        }
        const res = await this.$store.dispatch('gigaoffice/getGoProductCostInfo', payload)
        // console.log(res.data.response.results)
        this.productCostInfo = res.data.response.results
      } catch (err) {
        console.log(err)
      }
    },
		// CloudDirect 조회
		async getCloudDirectList() {
			const res = await this.getComCodeList('CD')
			this.cloudDirectInfo = res.data.response.results
		},
		// CloudDirect 속도 조회
		async getCloudDirectSpeedList() {
			const res = await this.getComCodeList('BS')
			this.cloudDirectSpeed = res.data.response.results
		},
    async goProductInfo () {
      try {
        const payload = {
          token_id: sessionStorage.getItem('accessToken')
        }
        const res = await this.$store.dispatch('gigaoffice/getGoProductInfo', payload)
        this.productInfo = res.data.response.results
      } catch (err) {
        console.log(err)
      }
    },
    // ip 개수 계산
		getaddIpCnt_temp(type) {
			let minIpCnt = 0;
			let maxIpCnt = 0;
			let vIpCnt = 0;
			switch(this.data_sel.svctypecd) {
				case '11' : //basic 11
				minIpCnt = 1
				maxIpCnt = 2
				vIpCnt = 1
				break;
				case '20' : //premium 20
				case '30' : //premium+ 30
				minIpCnt = 16
				maxIpCnt = 256
				vIpCnt = 16
				break;
				case '31' : //standard 31 or compact 32
				case '32' :
				minIpCnt = 8
				maxIpCnt = 256
				// vIpCnt = 0
				// PLUS
				if (type == '+') {
					if (this.addIpCnt_temp < maxIpCnt) {
						this.addIpCnt_temp += 2
					}
				// MINUS
				} else {
					if(this.addIpCnt_temp > minIpCnt) {
						this.addIpCnt_temp /= 2
					// } else if(this.addIpCnt_temp == 0) {
						
					} else {
						return false
					}
				}
				break;
        case '34' ://simple 34
          break;
      }
      if(type == '+') {
        if(this.addIpCnt_temp < maxIpCnt ) {
          this.addIpCnt_temp += vIpCnt
        }
      } else {
        if(this.addIpCnt_temp > 0) {
          this.addIpCnt_temp -= vIpCnt
        }
      }
      this.getAddIpCost()
      this.getCost()
		},
    // 상품가격
    async getCost (type) {
			// var beforeCost = this.useAmt_temp
			// console.log('----getCost----')
			// console.log('서비스='+this.svcTypeCd)
			// console.log('속도='+this.lineSpeedInfo_sel)
			// console.log('기간='+this.termCntrctTypeCd)

			// console.log('기존서비스='+this.data_sel.svctypecd)
			// console.log('기존속도='+this.data_sel.linespeccd)
			// console.log('기존기간코드='+this.data_sel.termcntrcttypecd)
      
      // if(this.svcMasterInfo.contractperiodtypecodedesc == '기간없음') {
      // if(this.data_sel.contractperiodtypecodedesc == '기간없음') {
      //   this.termCntrctTypeCd = 'EQ0Y'
      // } else if (this.svcMasterInfo.contractperiodtypecodedesc == '2년') {
      //   this.termCntrctTypeCd = 'EQ2Y'
      // } else if (this.svcMasterInfo.contractperiodtypecodedesc == '3년') {
      //   this.termCntrctTypeCd = 'EQ3Y'
      //   this.nrcExemptRsnCd = '86'
      // } else if (this.svcMasterInfo.contractperiodtypecodedesc == '4년') {
      //   this.termCntrctTypeCd = 'EQ4Y'
      //   this.nrcExemptRsnCd = '86'
      // } else {
      //   this.termCntrctTypeCd = 'EQ5Y'
      //   this.nrcExemptRsnCd = '86'
      // }

      //기존 가격 세팅
      if(type == 'init') {
        this.svcTypeCd = this.data_sel.svctypecd
        this.termCntrctTypeCd = this.data_sel.termcntrcttypecd
        this.lineSpeedInfo_sel = this.data_sel.linespeccd
        await this.productCostInfo.forEach(item => {
          if (item.svc_type_cd == this.data_sel.svctypecd) {
            if (item.term_cntrct_type_cd == this.data_sel.termcntrcttypecd) {
              if(this.data_sel.svctypecd == '11' || this.data_sel.svctypecd == '20' || this.data_sel.svctypecd == '30') {
                if (item.line_spec_cd == this.data_sel.linespeccd) {
                  this.cntrctInstChg_before = item.cntrct_inst_chg
                  this.equipUseChg_before = item.equip_use_chg
                  this.termCntrctChg_before = item.term_cntrct_chg
                  this.svcTotalChg_before = item.svc_total_chg
                  // console.log('flex 기존상품가격:')
                  // console.log(item)
                }
              } else {
                this.cntrctInstChg_before = item.cntrct_inst_chg
                this.equipUseChg_before = item.equip_use_chg
                this.termCntrctChg_before = item.term_cntrct_chg
                this.svcTotalChg_before = item.svc_total_chg
                // console.log('giga 기존상품가격:')
                // console.log(item)
              }
            }
          }
        })
      //변경 되는 요금
      } else {
        await this.productCostInfo.forEach(item => {
        if (item.svc_type_cd == this.data_sel.svctypecd) {
          if (item.term_cntrct_type_cd == this.data_sel.termcntrcttypecd) {
						if(this.data_sel.svctypecd == '11' || this.data_sel.svctypecd == '20' || this.data_sel.svctypecd == '30') {
							if (item.line_spec_cd == this.lineSpeedInfo_temp) {
								this.cntrctInstChg_temp = item.cntrct_inst_chg
								this.equipUseChg_temp = item.equip_use_chg
								this.termCntrctChg_temp = item.term_cntrct_chg
								this.svcTotalChg_temp = item.svc_total_chg
								// console.log('flex 변경될상품가격:')
								// console.log(item)
							}
						} else {
							this.cntrctInstChg_temp = item.cntrct_inst_chg
							this.equipUseChg_temp = item.equip_use_chg
							this.termCntrctChg_temp = item.term_cntrct_chg
							this.svcTotalChg_temp = item.svc_total_chg
              // console.log('giga 변경될상품가격:')
              // console.log(item)
						}
          }
        }
      })
      }
      // console.log(this.svcTypeCd)
      // console.log(this.termCntrctTypeCd)
      // console.log(this.lineSpeedInfo_temp)
      
			this.getSum()
    },
		// ip당 가격
    async getGoSubProductInfo () {
      try {
        const payload = {
          token_id: sessionStorage.getItem('accessToken')
        }
        const res = await this.$store.dispatch('gigaoffice/getGoSubProductInfo', payload)
        this.subProductInfo = res.data.response.results
      } catch (err) {
        console.log(err)
      }
    },
    // 추가 ip가격
		async getAddIpCost() {
		await this.subProductInfo.forEach(item => {
			if (item.svc_type_cd == this.data_sel.svctypecd) {
        //기존 추가 IP가격
        if (item.addsvc_req_cnt == this.addIpCnt_before) {
          this.addsvcCntrctChg_before = item.addsvc_cntrct_chg
        }
        // 변경될 추가 IP가격
        if (item.addsvc_req_cnt == this.addIpCnt_temp) {
					this.addsvcCntrctChg_temp = item.addsvc_cntrct_chg
				}
			}
		})
    // console.log('IP 변경 금액:'+parseInt(this.addsvcCntrctChg_temp - this.addsvcCntrctChg_before))
		// beforeIpCost += this.addsvcCntrctChg
		// this.useAmt = beforeIpCost

		},
		// cloud direct 상품별 가격
		async getCloudDirectCostInfo () {
      try {
        const payload = {
          bgSvcTypeCd: this.cloudDirectInfo_sel == '' ? this.data_sel.subsacd : this.cloudDirectInfo_sel
        }
        const res = await this.$store.dispatch('gigaoffice/getCloudDirectCostInfo', payload)
				this.cloudDirectCostInfo = res.data.response.list
      } catch (err) {
        console.log(err)
      }
		},
    // clouddirect 가격
		getSubCost() {
			console.log('----getSubCost----')
			this.cloudDirectCostInfo.forEach(item => {
				if(item.lineSpecCd == this.cloudDirectSpeed_sel){
					this.cntrctInstChg_sub_temp = item.cntrctInstChg
					this.equipUseChg_sub_temp = item.equipUseChg
					this.svcTotalChg_sub_temp = item.svcTotalChg
					// console.log('svcTotalChg_sub : '+this.svcTotalChg_sub)
					// temp = parseInt(this.svcTotalChg_sub)
					// this.useAmt = temp
				}
			})
			this.getSum()
    //총 가격 useAmt
    },
    getSum() {
			if(!this.svcTypeCd) return false
			if(!this.lineSpeedInfo_sel) return false
			var sum = this.svcTotalChg
			// sum += this.svcTotalChg_sub
			if(this.addIpCnt_temp != 0) sum += this.addsvcCntrctChg
			sum += this.uploadCost_after
			sum += this.equipUseChg_sub_after
			if(this.cloudDirectSpeed_sel != '') sum += this.cntrctInstChg_sub_after
			this.useAmt = parseInt(sum)
      //차액
      this.useAmt_gap = this.termCntrctChg_gap + this.uploadCost_gap + this.addsvcCntrctChg_gap + this.cntrctInstChg_after + this.useAmt_sub_gap
		},
    //1-1. 토큰으로 사업자번호/법인번호 조회
    async getOwnerContactInfoByTokenId() {
      // let tokenId = sessionStorage.getItem('accessToken')
      //TODO TEST tokenId
      // let tokenId = '081d0f51f3f1a1d3e0a817e1951fc22c4eacc3c052f4849dd75aeb3be845407ce4bb060513d86b0baf36b86c95a0281d'
      let token_id = sessionStorage.getItem("accessToken");
      let param = { token_id: token_id };
      await this.$store.dispatch("auth/getOwnerContactInfoByTokenId", param).then(res => {
        this.ownerContactInfo = res;
        // this.getUserInfo(this.ownerContactInfo.partyIdNumber1)
        /// / 고객정보 에서 코드 값 보정 시퀀스 ///
        if (this.ownerContactInfo.partyDetailTypeCd == '03') {
          this.partyDetailTypeCd = '3'
        }
        else if (this.ownerContactInfo.partyDetailTypeCd == '08') {
          this.partyDetailTypeCd = '8'
        }
        else if (this.ownerContactInfo.partyDetailTypeCd == '06') {
          this.partyDetailTypeCd = '3'
        }
        let pno = this.partyDetailTypeCd == '3' ? this.ownerContactInfo.partyIdNumber1 : this.ownerContactInfo.partyIdNumber2
        setTimeout(() => {
          this.getUserInfo(pno)//사업자번호
        }, 300); 
      })
      .catch(error => {
        console.log(error);
      });
    },
    // 고객 정보 조회
		getUserInfo: async function(partyIdNumber) {
			//TODO TEST
			let param = {custId: partyIdNumber}
      console.log()
			// let param = {custId: '1048207072'}
			await this.$store.dispatch("gigaoffice/getUserInfo", param).then((res) => {
				this.userInfo = res.data.result
			})
			.catch(error => { 
				console.log(error)
			})
		},
    //2. 사업자/법인번호에 대한 고객의 전용번호/상품내역 조회
    async getUserSvcInfo() {
      if (
        this.partyIdNumber == "01" &&
        (this.partyIdNumber1 == '' || this.partyIdNumber1 == null)
      ) {
        alert(
          "조회 정보가 입력 되지 않았습니다. 사업자등록번호 또는 법인번호를 입력해주세요."
        );
        return false;
      }
      if (
        this.partyIdNumber == "02" &&
        (this.partyIdNumber2 == "" || this.partyIdNumber2 == null)
      ) {
        alert(
          "조회 정보가 입력 되지 않았습니다. 사업자등록번호 또는 법인번호를 입력해주세요."
        );
        return false;
      }
      //사업자등록번호 (10자리) / 법인번호 (13자리)
      this.selectedSearchTypeCd != "01" ? (this.custType = "8") : (this.custType = "3");
      this.selectedSearchTypeCd != "01" ? (this.custNum = this.partyIdNumber1.replace(/-/gi, "")) : (this.custNum = this.partyIdNumber2.replace(/-/gi, ""));

      //TODO test data
      // this.custType = this.partyIdNumber == '01' ? '8' : '3'
      // this.custNum = this.partyIdNumber == '01' ? this.partyIdNumber1.replace(/-/gi,'') : this.partyIdNumber2.replace('-','')

      let param = { custType: this.custType, custNum: this.custNum };
      //api호출
      let res = await gigaofficeService.getUserSvcInfo(param);
      if (res.data.code == "100") {
        var count = res.data.count;
        var result = res.data.result;
        this.userSvcInfo = "";
        if (res.data.count > 0) {
          this.userSvcInfo = result.filter(item => item.scn == this.data_sel.said)
            // this.userSvcInfo.addr =
            //   result.addr +
            //   result.addrnum +
            //   result.addrsubnum +
            //   result.detailaddr;
          // this.bizr_rgst_no = this.partyIdNumber1
          // this.crpr_no = this.partyIdNumber2
        }
      } else {
        alert("조회 중 오류가 발생했습니다.\n error : " + res.data.code);
      }
    },
    //베이직 외 상품
    async getSvcMasterInfo(scn) {
      let param = { scn: scn };
      //api호출
      let res = await gigaofficeService.getSvcMasterInfo(param);
      if (res.data.code == "100") {
        this.svcMasterInfo = res.data.result;
        console.log('this.svcMasterInfo')
        console.log(this.svcMasterInfo)
        // console.log(res.data.result.addservices)
        this.addservices = res.data.result.addservices[0];
        await this.getLineSpeedList(this.data_sel.svctypecd)
      } else if (res.data.code == "9250") {
        alert("조회된 데이터가 없습니다.");
      } else {
        alert(res.data.message);
      }
    },
    //베이직 상품
    async getSvcMasterInfo2(scn) {
      let param = { scn: scn };
      //api호출
      let res = await gigaofficeService.getSvcFlexlineMasterInfo(param);
      if (res.data.code == "100") {
        this.svcMasterInfo = res.data.result;
        // console.log(res.data.result.addservices)
        this.addservices = res.data.result.addservices[0];
        await this.getLineSpeedList(this.data_sel.svctypecd)
      } else if (res.data.code == "9250") {
        alert("조회된 데이터가 없습니다.");
      } else {
        alert(res.data.message);
      }
    },
    // async getSubscriptionListInfo() {
    //   let param = {cust_ind: custId}
				
    //   console.log(param)
    //   //신청내역조회 api호출
    //   this.$store.dispatch('gigaoffice/getSubscriptionListInfo', param).then((res) => {
    //     //결과담기
    //     console.log(res)
    //     console.log(res.results)
    //     this.subscriptionList = res.results
    //     // console.log(this.subscriptionList)
    //   })
    // },
    
    // async getSubscriptionListProductDetail() {
    //   let param = {alpsht_id:}
    // },
    // async getAuthContactInfo () {
		// 	this.contactInfo = ''
		// 	this.userInfo = ''
    //     const payload = {
    //       token_id: sessionStorage.getItem('accessToken')
    //     }
    //     await this.$store.dispatch('gigaoffice/getAuthContactInfo', payload).then(res => {
		// 			if (res.count === 0) {
		// 				alert('등록된 고객정보가 없습니다. 고객정보를 등록해주세요.')
		// 			} else {
		// 				this.contactInfo = res.data.result
		// 				if (this.contactInfo.partyIdNumber1 != null && this.contactInfo.partyIdNumber1 !== '') {
		// 					this.partyIdNumber1 = this.contactInfo.partyIdNumber1
		// 				}
		// 				if (this.contactInfo.partyIdNumber2 != null && this.contactInfo.partyIdNumber2 !== '') {
		// 					this.partyIdNumber2 = this.contactInfo.partyIdNumber2
		// 				}
		// 				// 임시 저장
		// 				this.selectEmail = this.contactInfo.email
		// 				this.selectMobile = this.contactInfo.mobileNumber
		// 				this.selectPhone = this.contactInfo.phoneNumber

		// 				this.partyIdNumber1 = res.data.result.partyIdNumber1
		// 				this.partyIdNumber2 = res.data.result.partyIdNumber2
		// 				this.partyDetailTypeCd = res.data.result.partyDetailTypeCd
		// 				this.partyName = res.data.result.partyName
		// 				/// / 고객정보 에서 코드 값 보정 시퀀스 ///
		// 				if (this.partyDetailTypeCd === '03') {
    //           this.partyDetailTypeCd = '3'
		// 				}
		// 				if (this.partyDetailTypeCd === '08') {
    //           this.partyDetailTypeCd = '8'
		// 				}
		// 				if (this.partyDetailTypeCd === '06') {
    //           this.partyDetailTypeCd = '3'
		// 				}
		// 				if ((this.partyIdNumber2 === '') || (this.partyIdNumber2 === undefined) || (this.partyIdNumber2 === null)) {
    //           this.partyIdNumber2 = this.partyIdNumber1
		// 				}
    //         var patyIdNo = this.partyDetailTypeCd == '8' ? this.partyIdNumber1 : this.partyIdNumber2
    //         // this.getUserInfo(patyIdNo)
		// 			}
		// 		})
		// 		.catch(error => {
		// 			console.log(error)
		// 		})
    // },
    // 주소 검색
    async getCustAddressList () {
      const payload = {
        token_id: sessionStorage.getItem('accessToken'),
        dong: this.dong
      }
      await this.$store.dispatch('gigaoffice/getCustAddressList', payload).then(res => {
				// 동 세팅
				this.addrList = res.data.response.results
				// 초기화
				this.bldgname = ''
			})
			.catch(err => {
				console.log(err)
			})
		},
		// 건물 검색
		async getCustAddressBldgList () {
			const payload = {
				token_id: sessionStorage.getItem('accessToken'),
				bldgname: this.bldgname,
				addrseq: this.addrInfo_temp.addrseq
			}
			await this.$store.dispatch('gigaoffice/getCustBldgList', payload).then(res => {
				this.buildingList = res.data.response.results
			})
			.catch (err => {
        console.log(err)
      })
    },
    //팝업 오픈
		async openPopup(type) {
      switch (type) {
			case 'locationInfo' :
				this.popLocationInfo = true
        this.locationFlag = false
				setTimeout(() => {
					this.$refs.dong.focus()
				}, 1)
				break
			case 'addrInfo' :
				if (this.dong === '') {
					alert('검색을 원하시는 동 이름을 입력해주세요.')
					this.$refs.dong.focus()
					return false
				}
				await this.getCustAddressList()
				this.popAddrInfo = true
        this.addrFlag = false
				setTimeout(() => {
          this.$refs.dong2.focus()
				}, 1)
				break
			case 'buildingInfo' :
        if (this.dong === '') {
					alert('주소 검색 후에 가능합니다.')
					return false
				}
				if (this.bldgname === '') {
          alert('검색을 원하시는 건물 이름을 입력해주세요.')
					return false
				}
				this.detailAddr = ''
				this.popBuildingInfo = true
        this.buildingFlag = false
				await this.getCustAddressBldgList()
				break;
      case 'basicSvcInfo' :
        //기존데이터
        this.setData()
        this.popBasicSvcInfo = true
        this.basicSvcFlag = false
        // this.addIpCnt_temp
        break
      case 'addSvcInfo' :
        this.popAddSvcInfo = true
        this.addSvcFlag = false
        break
      
			}
		},
		//팝업 닫기
		closePopup(type) {
			switch (type) {
			// case 'userInfo' :
			// 	this.userInfo_sel = this.userInfo_temp
			// 	this.custNoType = this.userInfo_sel.custTypeCd
			// 	this.popUserInfo = false
			// 	console.log(this.userInfo_sel)
			// 	break
			case 'locationInfo' :
        if(this.addrInfo_temp == '') {
          alert('변경된 주소정보가 없습니다.')
          return false
        }
        if(this.buildingInfo_temp == '') {
          alert('변경된 건물정보가 없습니다.')
          return false
        }
        if(this.detailAddr == '') {
          alert('입력된 상세주소가 없습니다.')
          return false
        }
        this.addrInfo_sel = this.addrInfo_temp
				this.buildingInfo_sel = this.buildingInfo_temp
				this.fullAddr = `${this.buildingInfo_temp.addr1+this.buildingInfo_temp.addr2+this.detailAddr}`
        this.data_sel.addr = this.fullAddr

				this.popLocationInfo = false
        this.locationFlag = true

				break
			case 'addrInfo' :
				if (this.addrInfo_temp === '') {
					alert('검색 결과를 선택해주세요.')
					return false
				}
				this.addrInfo_sel = this.addrInfo_temp
				this.popAddrInfo = false
        this.addrFlag = true
				setTimeout(() => {
					this.$refs.building.focus()
				}, 1)
				break
			case 'buildingInfo' :
				if (this.buildingInfo_temp === '') {
					alert('검색 결과를 선택해주세요.')
					return false
				}
				if(this.detailAddr == '') {
					alert('상세주소를 입력해주세요.')
					return false
				}
				this.buildingInfo_sel = this.buildingInfo_temp
				this.popBuildingInfo = false
        this.buildingFlag = true
				break
			case 'basicSvcInfo' :
        if(this.lineSpeedInfo_temp == this.data_view.linespeccd 
        && this.uploadSpeedInfo_temp == this.data_view.uploadspd
        && this.addIpCnt_temp == this.data_sel.addipcnt) {
          alert('변경된 정보가 없습니다.')
          return false
        }
        //속도
        this.data_sel.linespeccd = this.lineSpeedInfo_temp
        this.data_sel.linespecnm = this.lineSpeedInfoNm_temp
        this.data_sel.uploadspd = this.uploadSpeedInfo_temp
        this.data_sel.uploadspdnm = this.uploadSpeedNm_temp
        //IP
        this.data_sel.addipcnt = this.addIpCnt_temp
        //IP변경 차액
        this.addsvcCntrctChg_gap = this.addsvcCntrctChg_temp - this.addsvcCntrctChg_before
        //기본서비스(다운로드속도) 변경 차액
        this.termCntrctChg_gap = this.termCntrctChg_temp - this.termCntrctChg_before
        //업로드속도 변경 차액
        this.uploadCost_gap = this.uploadCost_after - this.uploadCost_before

        this.popBasicSvcInfo = false
        this.basicSvcFlag = true
				break
      case 'addSvcInfo' :
        //Cloud
        if((this.svcTotalChg_sub_temp == this.data_view.bugauseamt) && (this.cloudDirectInfo_sel == this.data_view.subsacd)) {
          alert('기존 상품과 같습니다.')
          return false
        }
        this.data_sel.subsanm = this.subSaNm
        // this.cntrctInstChg_after = this.cntrctInstChg_temp 
				this.equipUseChg_after = this.equipUseChg_temp
				this.svcTotalChg_after = this.svcTotalChg_temp 
        this.svcTotalChg_sub_after = this.svcTotalChg_sub_temp
        //부가서비스 변경 차액
        this.useAmt_sub_gap = this.svcTotalChg_sub_temp - this.useAmt_sub_before
        this.popAddSvcInfo = false
        this.addSvcFlag = true
        break
			}
		},
    goOrder() {
      //flexline
      if(this.data_sel.svctypenm.substr(0,1) == 'F') {
        if(!this.basicSvcFlag && !this.addSvcFlag) {
          alert('변경된 정보가 없습니다.')
          return false
        }
      } else {
        if(!this.locationFlag) {
          alert('변경된 정보가 없습니다.')
          return false
        }
      }
			if(!confirm('상품을 변경신청하시겠습니까?')) {
				return false
			}
			this.postGoOrderSubscriptionReceipt()
		},
    //변경
    async postGoOrderSubscriptionReceipt() {
      let param = {
        tokenId: sessionStorage.getItem("accessToken") //"this.loginaccessToken"
        ,userId: sessionStorage.getItem('userId')
        ,acptType: '02' //"신청유형" 변경 02
        ,custNoType: this.partyDetailTypeCd //"고객유형"
        ,custNo: this.partyDetailTypeCd == '8'? this.ownerContactInfo.partyIdNumber1 : this.ownerContactInfo.partyIdNumber2 //"고객정보의 custId: 사업자번호"
        ,bizrCrprNm: this.userInfo_result[0].corpNm // "법인명(고객명)"
        ,crprNo: this.userInfo_result[0].corpNo //"법인등록번호(생년월일)"
        ,aplcntNm: this.userInfo_result[0].reprNm //"대표자명"
        ,bizrRgstNo: this.userInfo_result[0].custIdfyNo //"사업자등록번호"
        ,comNm: '' //"전산담당자 이름"
        ,comTel: '' //"전산담당자 연락처"
        ,comEmail: '' //"전산담당자 이메일"
        ,aplcntRltn: '' //"신청구분 - 본인신청/대리신청"
        ,addrRef: this.buildingFlag ? this.detailAddr : this.data_sel.addrref//"설치장소 상세주소"
        ,dongCd: this.addrFlag ? this.addrInfo_sel.dongcode : this.data_sel.dongcd //"빌딩정보의 dongcode"
        ,addrNoType: this.buildingFlag ? this.buildingInfo_sel.addr_no_type : this.data_sel.addrnotype//"bldg list의 addrtype '일반' or '산' 17 ////bldg list의 addrtype '일반' or '산' (bldg-list-info api 에서 선택해 올때 값 넣어주셔야 합니다)"
        ,addrNo: this.buildingFlag ? this.buildingInfo_sel.addr_no : this.data_sel.addrno //"bldg list addr num // 18 // //bldg list addr num // 18 (bldg-list-info api 에서 선택해 올때 값 넣어주셔요)"
        ,addrHo: this.buildingFlag ? this.buildingInfo_sel.addr_ho : this.data_sel.addrho //"bldg list addrsubnum // 19  ////bldg list addrsubnum // 19 (bldg-list-info api 에서 선택해 올때 값 넣어주셔요)"
        ,addrBldgId: this.buildingFlag ? this.buildingInfo_sel.bldgsvrcode : this.data_sel.addrbldgid //"bldg list 정보의 bldgsvrcode"
        ,billId: this.data_sel.billid //"청구정보ID-납부정보"
        ,saId: this.data_sel.said == null ? this.svcMasterInfo.scn : this.data_sel.said//"가입유형-기본 서비스 정보 ( -신규신청시 빈값)"
        ,svcTypeCd: this.data_sel.svctypecd //"서비스명"
        ,lineSpecCd: this.data_sel.linespeccd //"다운로드속도"
        ,uploadSpd: this.data_sel.uploadspd //"업로드속도"
        ,addIpCnt : this.addIpCnt_temp//"추가IP수"
				,ipAddrCnt : this.data_sel.ip_addrcnt//"기본제공IP수"
        ,termCntrctTypeCd: this.data_sel.termcntrcttypecd //"계약기간-기본 서비스 정보"
        ,subEquipCd: '' //"대여장비"
        ,maintAgentCd: this.data_sel.maintagentcd //"협력사"
        // ,completedResvDateHh: this.svcMasterInfo.svcstartdt //"개통희망일"
        ,subSaCd: this.cloudDirectFlag ? this.cloudDirectInfo_sel : ''//"cloud direct(기본서비스)-부가서비스정보"
        ,bugaSpdCd: this.cloudDirectSpeed_sel //cloud direct 속도
				,bugaUseAmt : this.cloudDirectFlag ? this.svcTotalChg_sub_after : ''//부가서비스 없으면 null로
        ,remark: '변경' //"신규청약 (-remark + / + com_nm + / + com_tel + / + com_email)"
        ,sellerInfo: this.data_sel.sellerinfo //"판매자 / 고객 (가입유형) / KT방배지점 (판매자영업점명) / asb (판매자 ID)"
        // ,selType: '' //"가입유형-판매자정보"
        // ,selNm: '' //"판매자(영업점)명-판매자정보"
        // ,selId: '' //"판매자ID-판매자정보"
        ,aplcntTelNo: this.ownerContactInfo.phoneNumber //"// 신청자 집 전화 번호"
        ,aplcntMobileNo: this.ownerContactInfo.mobileNumber //"// 신청자 휴대폰 번호"
        ,custInd: this.userInfo_result[0].custId //"//this.insertcustind[0] // 청구정보의 custId 고객정보 조회시 cust_ind 배열중 하나 // 3"
        ,nflCustNm: this.userInfo_result[0].custNm //"// 명의자명 고객정보 // 6"
        ,prdcType: this.data_sel.svctypenm.substr(0,1) == 'F' ? '16' : '15'// "//기가 오피스 : 15 고정 : 12 Flexline : 16"
        ,useAmt: this.useAmt //"전체총액 숫자로"
        ,cntcEmailAddr: this.ownerContactInfo.email //"//연락 email주소 //24"
        ,trtmStatCd: '1' //"1 -처리상태코드"
        ,equipGrpCd: this.data_sel.equipgrpcd == null ? '' : this.data_sel.equipgrpcd //"벤더사-단말모델코드"
        ,goOfcName: ''//"GO센터명"
        // ,equpLicnsTypeCd: ''//" TODO 공급장비라이선스타입- 현재화면에 선택란 없음" 
        ,ipUseAmt: this.addIpCnt_temp != '0' ? this.addsvcCntrctChg_temp : ''//"고정IP 협정요금"
        ,ipChargeCd: this.addIpCnt_temp != '0' ? '1' : ''//"IP 협정요금 부과여부" // 여 1, 부 null
        ,nrcExemptRsnCd: this.data_sel.nrcexemptrsncd == null ? '' : this.data_sel.nrcexemptrsncd  //"설치비면제여부" 3년이상약정시 면제
      };
      const formData = new FormData();
      formData.append("flexLineInsertReqVo", new Blob([JSON.stringify(param)], { type: "application/json" }));
      this.$store.dispatch("gigaoffice/postGoOrderSubscriptionReceipt", formData).then(res => {
        if(res.data.code == '100') {
					alert('서비스 변경 신청이 완료되었습니다.')
          this.$router.push('/')
				}
      }).catch(error => {
          console.log(error);
      });
    },
    addrInfoTemp(addrInfo) {
			this.addrInfo_temp = addrInfo
			setTimeout(() => {
				this.$refs.building.focus()
			}, 1)
			this.popAddrInfo = false
      // addr: "서울특별시 서초구 방배동"
      // addrseq: 921
      // dongcode: "306223"
      // zipcode: "137060"
    },
    buildingInfoTemp(buildingInfo) {
			this.buildingInfo_temp = buildingInfo
			// console.log(this.buildingInfo_temp)
			// this.popBuildingInfo = false
      // 			addr1: "서울특별시 서초구 방배동"
      // addr2: " 1001-1 번지 KT방배타워 A동"
      // addr_ho: "1"
      // addr_no: "1001"
      // addr_no_type: "일반"
      // addrseq: 921
      // bldgclscode: "BD"
      // bldgname: "KT방배타워"
      // bldgscode: "586555"
      // bldgsvrcode: "B0000000834"
      // bldgtypecode: "E"
      // bldgtypenm: "KT"
		},
    cloudDirectChange(e, boolean) {
			if(boolean) {
				if(this.data_sel.svctypecd == '11' || this.data_sel.svctypecd == '20') {
					this.cloudDirectFlag = true
				}
			//선택안함
			} else {
				this.cloudDirectFlag = false
				this.cloudDirectInfo_sel = ''
				this.cloudDirectSpeed_sel = ''
				this.$refs.cldSvc.innerHTML = `<span class="fcGy">선택하세요</span>`
				this.$refs.cldSvcSpd.innerHTML = `<span class="fcGy">최대 속도</span>`
			}
		},
  }
};
</script>
